import request from '@/utils/request'

export function roleMenuSave (data) {
  return request({
    url: '/api/back/employmentUser/role/menu',
    method: 'post',
    data
  })
}

export function roleMenuEdit (data) {
  return request({
    url: '/api/back/employmentUser/role/menu',
    method: 'put',
    data
  })
}

export function getRoleMenus (params) {
  return request({
    url: '/api/back/employmentUser/role/menu',
    method: 'get',
    params
  })
}
