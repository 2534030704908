<template>
  <a-modal
    :visible="visible"
    title="角色授权"
    @ok="handleSubmit"
    @cancel="close"
    width="500px"
    :confirmLoading="loading"
  >
    <div class="permission_tree" v-if="visible">
      <a-input-search style="margin-bottom: 8px" @change="handleOnChange" placeholder="菜单名称" />
      <div class="tree_content">
        <a-tree
          :auto-expand-parent="autoExpandParent"
          :tree-data="menuTree"
          checkStrictly
          :expanded-keys="expandedKeys"
          checkable
          :filterTreeNode="filterTreeNode"
          v-model="checkedKeys"
          @expand="handleExpand"
          @check="handleCheck"
          :replaceFields="{
            children: 'children',
            title: 'name',
            key: 'id',
          }"
        >
          <template slot="custom" slot-scope="item">
            <div class="tree-node-box">
              <span class="tree-node-name">{{ item.name }}</span>
              <span
                class="tree-node-all"
                v-if="item.children && item.children.length"
                @click="handleCheckAll(item)"
              >全选</span
              >
            </div>
          </template>
        </a-tree>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { roleMenuSave, getRoleMenus } from '@/api/employmentUser/roleMenu'
import { tree } from '@/api/employmentUser/menu'
import { Tree } from 'ant-design-vue'

export default {
  name: 'Permission',
  components: {
    'a-tree': Tree
  },
  data () {
    return {
      search: '',
      expandedKeys: [],
      checkedKeys: [],
      menuTree: [],
      autoExpandParent: true,
      visible: false,
      loading: false,
      roleId: ''
    }
  },
  methods: {
    handleSubmit () {
      this.loading = true
      roleMenuSave({
        roleId: this.roleId,
        menuIds: this.checkedKeys
      })
        .then((res) => {
          if (res.success) {
            this.$emit('success')
            this.$message.success('保存成功')
            this.close()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleGetParentIds (treeList, value) {
      return treeList.reduce((parentIds, item) => {
        if (item.name.indexOf(value) > -1) {
          parentIds.push(...item.parentIds)
        }
        if (item.children && item.children.length) {
          parentIds.push(...this.handleGetParentIds(item.children, value))
        }
        return parentIds
      }, [])
    },
    handleOnChange (e) {
      const value = e.target.value
      if (value) {
        const expandedKeys = [...new Set(this.handleGetParentIds(this.menuTree, value))]
        Object.assign(this, {
          expandedKeys,
          search: value,
          autoExpandParent: true
        })
      } else {
        Object.assign(this, {
          expandedKeys: [],
          search: value,
          autoExpandParent: false
        })
      }
    },
    handleCheck ({ checked: checkedKeys }, { checked, node }) {
      const { parentId, id, children, parentIds } = node.dataRef
      if (checked) {
        this.$set(this, 'checkedKeys', [...new Set([...checkedKeys, ...parentIds])])
      } else {
        const ids = this.handleGetChildrenIds(children, [])
        this.$set(
          this,
          'checkedKeys',
          checkedKeys.filter((item) => !ids.includes(item))
        )
      }
    },
    handleCheckAll (data) {
      const { parentId, id, children } = data
      const ids = [...this.handleGetChildrenIds(children, []), id]
      if (parentId) {
        ids.push(parentId)
      }
      this.$set(this, 'checkedKeys', [...new Set([...this.checkedKeys, ...ids])])
    },
    handleGetChildrenIds (data, ids) {
      if (data && data.length) {
        data.forEach((item) => {
          ids.push(item.id)
          if (item.children && item.children.length) {
            this.handleGetChildrenIds(item.children, ids)
          }
        })
      }
      return ids
    },
    handleCBTree (treeData, parentIds = []) {
      if (treeData && treeData.length) {
        return treeData.map((item) => ({
          ...item,
          scopedSlots: { title: 'custom' },
          selectable: true,
          parentIds: parentIds,
          children: this.handleCBTree(item.children, [...parentIds, item.id])
        }))
      }
      return []
    },
    async init (roleId, clientId) {
      this.roleId = roleId
      this.visible = true
      if (!this.menuTree.length) {
        await tree({ clientId }).then((res) => {
          this.menuTree = this.handleCBTree(res.data)
        })
      }
      await getRoleMenus({ roleId }).then((res) => {
        if (res.success) {
          this.checkedKeys = res.data.map((item) => item.menuId)
          this.expandedKeys = res.data.map((item) => item.menuId)
        }
      })
    },
    filterTreeNode (node) {
      return this.search && node.dataRef.name.includes(this.search)
    },
    handleExpand (expandedKeys) {
      this.autoExpandParent = false
      this.expandedKeys = expandedKeys
    },
    close () {
      this.visible = false
      this.checkedKeys = []
      this.search = ''
      console.log(this.search)
      this.autoExpandParent = true
    }
  }
}
</script>

<style lang="less">
.permission_tree {
  .tree_content {
    margin-top: 15px;
    max-height: 400px;
    overflow: hidden auto;
  }
  .tree-node-all {
    color: #40a9ff;
    cursor: pointer;
    padding-left: 20px;
  }
  .ant-tree-node-selected {
    background-color: transparent !important;
  }
}
</style>
